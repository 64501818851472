/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { userInfo } from 'os'
import { IGroup } from 'src/app/models/group.interface'
import { AppStateService } from 'src/app/state/app-state.service'
import { AwsService } from 'src/service/aws/aws.service'

@Component({
	selector: 'rdsg-group-mgmt',
	templateUrl: './group-mgmt.component.html',
	styleUrls: ['./group-mgmt.component.scss']
})
export class GroupMgmtComponent implements OnInit {
	@Input() groupData: IGroup
	public _groupData: IGroup = null
	public inputMember: string = ''

	constructor(
		private readonly aws: AwsService,
		private modalCtrl: ModalController,
		private readonly state: AppStateService
	) {}

	public get currentUserName(): string {
		return this.state.globalUserName
	}

	public get isManager(): boolean {
		if (this._groupData) {
			const selfMember = this._groupData.members.find(m => m.user === this.state.globalUserName)
			return selfMember.owner
		} else return false
	}

	async ngOnInit() {
		try {
			const response = await this.aws.getGroupData(this.groupData.group)
			this._groupData = { ...this.groupData, members: response.map(u => ({ user: u.user, owner: u.owner })) }
		} catch (err) {
			console.error(err)
		}
	}

	public async handleToggleAdmin(userName: string): Promise<void> {
		const selfMember = this._groupData.members.find(m => m.user === this.state.globalUserName)
		if (!selfMember.owner) return

		const member = this._groupData.members.find(m => m.user === userName)
		return member.owner ? this.handleUnsetAdmin(userName) : this.handleSetAdmin(userName)
	}

	private async handleSetAdmin(userName: string): Promise<void> {
		try {
			const payload: IGroup = {
				group: this.groupData.group,
				user: userName,
				description: this.groupData.description,
				owner: true
			}
			const response = await this.aws.createGroup(payload)

			const index = this._groupData.members.findIndex(m => m.user === userName)
			let newArray = this._groupData.members
			newArray[index] = { ...newArray[index], owner: true }

			this._groupData = {
				...this._groupData,
				members: [...newArray]
			}
			this.inputMember = ''
		} catch (error) {
			console.error(error)
		}
	}

	private async handleUnsetAdmin(userName: string): Promise<void> {
		try {
			const payload: IGroup = {
				group: this.groupData.group,
				user: userName,
				description: this.groupData.description,
				owner: true
			}
			const response = await this.aws.createGroup(payload)

			const index = this._groupData.members.findIndex(m => m.user === userName)
			let newArray = this._groupData.members
			newArray[index] = { ...newArray[index], owner: false }

			this._groupData = {
				...this._groupData,
				members: [...newArray]
			}
			this.inputMember = ''
		} catch (error) {
			console.error(error)
		}
	}

	public async handleAddMember(): Promise<void> {
		try {
			const payload: IGroup = {
				group: this.groupData.group,
				user: this.inputMember,
				description: this.groupData.description,
				owner: false
			}
			const response = await this.aws.createGroup(payload)
			this._groupData = {
				...this._groupData,
				members: [...this._groupData.members, { user: this.inputMember, owner: false }]
			}
			this.inputMember = ''
		} catch (error) {
			console.error(error)
		}
	}

	public async handleRemoveMember(userName: string): Promise<void> {
		try {
			const payload: any = {
				group: this.groupData.group,
				user: userName
			}
			const response = await this.aws.removeFromGroup(payload)

			const index = this._groupData.members.findIndex(m => m.user === userName)
			let newArray = this._groupData.members
			newArray.splice(index, 1)

			this._groupData = {
				...this._groupData,
				members: [...newArray]
			}
		} catch (error) {
			console.error(error)
		}
	}

	public async onDismissModal(): Promise<boolean> {
		return await this.modalCtrl.dismiss()
	}
}
