/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { IGroup } from 'src/app/models/group.interface'
import { AppStateService } from 'src/app/state/app-state.service'
import { AwsService } from 'src/service/aws/aws.service'
import { GroupMgmtComponent } from '../group-mgmt/group-mgmt.component'

@Component({
	selector: 'rdsg-group-create',
	templateUrl: './group-create.component.html',
	styleUrls: ['./group-create.component.scss']
})
export class GroupCreateComponent implements OnInit {

	@Input() isFirstGroup: boolean

	groupForm = this.fb.group({
		name: ['', Validators.required],
		description: ['', Validators.required]
	})

	public inputMember: string = ''

	public members: string[] = []

	constructor(
		private readonly aws: AwsService,
		private fb: FormBuilder,
		private modalCtrl: ModalController,
		private readonly state: AppStateService
	) {}

	ngOnInit() {}

	public handleAddMember(): void {
		this.members.push(this.inputMember)
		this.inputMember = ''
	}

	public async handleCreateGroup(): Promise<void> {
		try {
			const payload: IGroup = {
				group: this.groupForm.value.name,
				user: this.state.globalUserName,
				description: this.groupForm.value.description,
				owner: true
			}

			const response = await this.aws.createGroup(payload)
			if (response) {
				this.onDismissModal()

				const modal = await this.modalCtrl.create({
					component: GroupMgmtComponent,
					showBackdrop: false,
					id: 'GROUP_MANAGE_HANDLER',
					componentProps: {
						groupData: payload
					}
				})
				await modal.present()
			} else return
		} catch (error) {}
	}

	public async onDismissModal(): Promise<boolean> {
		return await this.modalCtrl.dismiss()
	}
}
