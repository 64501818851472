/** @format */

import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'
import { AppStateService } from '../state/app-state.service'

@Injectable({
	providedIn: 'root'
})
export class WatchDogGuard implements CanActivate, CanLoad {
	constructor(private readonly router: Router, private readonly state: AppStateService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.state.isTokenValid()) {
			if (state.url === '/login') this.router.navigate(['landing'])
			return true
		} else {
			if (state.url === '/login') return true
			else {
				this.router.navigate(['login'])
				return true
			}
		}
	}

	canLoad(
		route: Route,
		segments: UrlSegment[]
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.state.isTokenValid()) {
			if (route.path === 'login') this.router.navigate(['landing'])
			return true
		} else {
			this.router.navigate(['login'])
			return false
		}
	}
}
