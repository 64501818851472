/** @format */

import { Options } from '@angular-slider/ngx-slider'
import { Component, OnInit, Input, HostListener } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { CalendarComponentOptions } from 'ion2-calendar'
import moment, { Moment } from 'moment'
import { BehaviorSubject, Observable } from 'rxjs'
import { ICam, ICamHist } from 'src/app/models/cam.interface'
import { AwsService } from 'src/service/aws/aws.service'
moment.locale('pt-br')

@Component({
	selector: 'rdsg-cam-timeline',
	templateUrl: './cam-timeline.component.html',
	styleUrls: ['./cam-timeline.component.scss']
})
export class CamTimelineComponent implements OnInit {
	@Input() cams: ICam[]
	@Input() icon: any
	cam: ICam
	geocoder
	events: any[]
	camsHist: ICamHist[]

	baseMarker: string = '../../../assets/marker/'

	@HostListener('document:keydown.escape', ['$event'])
	handleKeyPress(event: KeyboardEvent) {
		if (this.isFullScreen) this.toggleFullScreen()
	}

	modalRef: HTMLIonModalElement = null

	//Variables related to the player controls

	public olderDate = moment().subtract(60, 'days').toDate()
	public presentDate = moment()
	public runningDate = null

	public timelinePeriod: { range: number; endTime: string; startTime: string } = {
		range: null,
		endTime: this.presentDate.toISOString(),
		startTime: this.presentDate.toISOString()
	}

	public displayPeriod: { range: number; endTime: string; startTime: string } = { ...this.timelinePeriod }

	public optionsRange: CalendarComponentOptions = {
		from: this.olderDate,
		to: this.presentDate.toDate(),
		pickMode: 'single',
		showMonthPicker: false,
		monthPickerFormat: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		weekdays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
	}

	public playbackSpeed: number = 1

	public isFullScreen: boolean = false

	public seekbarCtrl: any = 0
	public seekbarRangeInSeconds: number = 21600

	private knobBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(0)
	public knobObservable: Observable<any> = this.knobBehaviorSubject.asObservable()

	private playerStatusBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(null)
	public playerStatusObservable: Observable<any> = this.playerStatusBehaviorSubject.asObservable()

	public sliderOptions: Options = {
		floor: 0,
		ceil: this.seekbarRangeInSeconds,
		showSelectionBar: true,
		keyboardSupport: false,
		hideLimitLabels: true,
		hidePointerLabels: true
	}

	//Variables related to date control

	public currentDate: Moment = this.presentDate

	public period = 'dawn' //0 - dawn, 1 - morning, 2 - noon, 3 - night

	constructor(public aws: AwsService, public modalCtrl: ModalController) {}

	async ngOnInit() {
		this.modalRef = await this.modalCtrl.getTop()
		this.cam = this.cams[0]
		this.cam['address'] = await this.getAddressByLatLng(this.cam['position'])
		this.getHistoric(this.cam)
	}

	ionViewWillEnter() {
		this.setPeriod('dawn')
	}

	public trackerListener($event): void {
		this.seekbarCtrl = $event
		this.runningDate = moment(this.displayPeriod.startTime).add($event, 'seconds')
	}

	public updateStartDate(newStartDate: any) {
		this.displayPeriod.startTime = moment(newStartDate).toISOString()
	}

	public updateSliderRange(newRange: any) {
		this.seekbarRangeInSeconds = parseInt(newRange)
		this.sliderOptions = {
			...this.sliderOptions,
			ceil: this.seekbarRangeInSeconds
		}

		this.displayPeriod.endTime = moment(this.displayPeriod.startTime)
			.add(this.seekbarRangeInSeconds, 'seconds')
			.toISOString()
	}

	public skipTime(time: number) {
		let newTime = this.seekbarCtrl + time
		this.moveSeekbarKnob({
			value: newTime <= 0 ? 0 : newTime >= this.seekbarRangeInSeconds ? this.seekbarRangeInSeconds : newTime
		})
	}

	public knobGhostUpdate($event): void {
		this.runningDate = moment(this.displayPeriod.startTime).add($event.value, 'seconds')
	}

	public moveSeekbarKnob($event): void {
		this.knobBehaviorSubject.next($event.value)
	}

	public stopVideo(): void {
		this.playerStatusBehaviorSubject.next('pause')
	}

	public async toggleFullScreen() {
		this.isFullScreen = !this.isFullScreen
		if (this.isFullScreen) this.modalRef.cssClass = `${this.modalRef.cssClass} fullscreen`
		else this.modalRef.cssClass = 'rdsg-timeline-modal'
	}

	public setPeriod(period: string): void {
		this.period = period
		this.timelinePeriod.range = 6

		if (period === 'dawn') {
			this.timelinePeriod.endTime = moment(this.currentDate.toString())
				.set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
				.toISOString()

			this.timelinePeriod.startTime = moment(this.timelinePeriod.endTime)
				.add(-this.timelinePeriod.range, 'hours')
				.toISOString()
		} else if (period === 'morning') {
			this.timelinePeriod.endTime = moment(this.currentDate.toString())
				.set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
				.toISOString()

			this.timelinePeriod.startTime = moment(this.timelinePeriod.endTime)
				.add(-this.timelinePeriod.range, 'hours')
				.toISOString()
		} else if (period === 'noon') {
			this.timelinePeriod.endTime = moment(this.currentDate.toString())
				.set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
				.toISOString()

			this.timelinePeriod.startTime = moment(this.timelinePeriod.endTime)
				.add(-this.timelinePeriod.range, 'hours')
				.toISOString()
		} else if (period === 'night') {
			this.timelinePeriod.endTime = moment(this.currentDate.toString())
				.set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
				.toISOString()

			this.timelinePeriod.startTime = moment(this.timelinePeriod.endTime)
				.add(-this.timelinePeriod.range, 'hours')
				.toISOString()
		} else {
			this.timelinePeriod.endTime = moment(this.currentDate.toString()).toISOString()
		}

		this.displayPeriod = { ...this.timelinePeriod }
	}

	public changeTimelinePeriod($event): void {
		this.currentDate = $event.toString()
		this.setPeriod(this.period)
	}

	async getHistoric(cam) {
		this.camsHist = await this.aws.getBackup(cam.id)
		this.camsHist.forEach(cam => {
			cam.dthEnd = new Date(cam.dthEnd)
			cam.dthStart = new Date(cam.dthStart)
			cam['duration'] = new Date(cam.dthEnd.getTime() - cam.dthStart.getTime())
		})
		console.log(this.camsHist)
	}

	closeModal() {
		this.modalCtrl.dismiss()
	}

	async download(event) {
		const download = await this.aws.getBackupVideo(event.key)
		window.open(download, '_blank')
	}

	async downloadHLS() {
		const download = await this.aws.getMP4({
			camera_id: this.cam.id.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			range: this.timelinePeriod.range,
			endTime: this.timelinePeriod.endTime
		})
		window.open(download, '_blank')
	}

	async getAddressByLatLng(position) {
		try {
			const results = await this.geocoder.geocode({ location: position })

			const address = results.results[0].formatted_address
			return address
		} catch (error) {
			console.error('Erro ao buscar localização')
		}
	}
}
