/** @format */

import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { FirstAccessGuard } from './guards/first-access.guard'
import { WatchDogGuard } from './guards/watch-dog.guard'

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },

	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
		canActivate: [WatchDogGuard]
	},
	{
		path: 'sing-up',
		// loadChildren: './pages/sing-up/sing-up.module#SingUpPageModule',
		loadChildren: () => import('./pages/sing-up/sing-up.module').then(m => m.SingUpPageModule)
	},
	{
		path: 'home',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
		canLoad: [WatchDogGuard],
		canActivate: [WatchDogGuard, FirstAccessGuard]
	},
	{
		path: 'main',
		loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule),
		canLoad: [WatchDogGuard],
		canActivate: [WatchDogGuard, FirstAccessGuard]
	},
	{
		path: 'landing',
		loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPagePageModule),
		canActivate: [WatchDogGuard, FirstAccessGuard]
	},
	{
		path: 'user',
		loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule),
		canLoad: [WatchDogGuard],
		canActivate: [WatchDogGuard]
	},
	{
		path: 'sign-up',
		loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule)
	},
	{
		path: 'forgot-pass',
		loadChildren: () => import('./pages/forgot-pass/forgot-pass.module').then(m => m.ForgotPassPageModule)
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
