/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ICam } from 'src/app/models/cam.interface'

@Component({
	selector: 'rdsg-cam-view',
	templateUrl: './cam-view.component.html',
	styleUrls: ['./cam-view.component.scss']
})
export class CamViewComponent implements OnInit {
	@Input() cams: ICam[]
	cam: ICam
	geocoder
	events: any[]

	modalRef: HTMLIonModalElement = null

	constructor(public modalCtrl: ModalController) {}

	ngOnInit() {
		this.cam = this.cams[0]
		this.initVars()
	}

	async initVars() {
		this.modalRef = await this.modalCtrl.getTop()
		this.cam['address'] = await this.getAddressByLatLng(this.cam['position'])
	}

	closeModal() {
		this.modalCtrl.dismiss()
	}

	async getAddressByLatLng(position) {
		try {
			const results = await this.geocoder.geocode({ location: position })

			const address = results.results[0].formatted_address
			return address
		} catch (error) {
			console.error('Erro ao buscar localização')
		}
	}
}
