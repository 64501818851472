/** @format */

import { Component, OnInit, Input } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { ICam } from 'src/app/models/cam.interface'

@Component({
	selector: 'app-cam-detail',
	templateUrl: './cam-detail.component.html',
	styleUrls: ['./cam-detail.component.scss']
})
export class CamDetailComponent implements OnInit {
	@Input() cams: ICam[] = []
	groupCams
	isList = false
	public camsSubject: BehaviorSubject<any> = new BehaviorSubject([])

	public cams$: Observable<any>
	constructor(public modalCtrl: ModalController) {
		this.cams$ = this.camsSubject.asObservable()
	}

	ngOnInit() {
		
		if (this.cams.length > 1) {
			
			this.isList = true
			this.camsSubject.next(this.cams)
		}
	}

	closeModal() {
		this.modalCtrl.dismiss()
	}

	
}
