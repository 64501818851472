/** @format */

import { Component } from '@angular/core'
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	constructor() {
		var moment = require('moment-timezone')
		moment.tz.setDefault('America/Sao_Paulo')
	}
}
