/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { AppStateService } from 'src/app/state/app-state.service'
import { AwsService } from 'src/service/aws/aws.service'
import { CommentCreateComponent } from '../comment-create/comment-create.component'

@Component({
	selector: 'app-alert-detail',
	templateUrl: './alert-detail.component.html',
	styleUrls: ['./alert-detail.component.scss']
})
export class AlertDetailComponent implements OnInit {
	@Input() marker: any
	baseMarker = '../../../assets/marker/'
	userInfo: any = {}

	constructor(public modalCtrl: ModalController, public aws: AwsService, public state: AppStateService) {}

	async ngOnInit() {
		this.getUserInfo()
		this.marker.item['address'] = await this.state.getAddressByLatLng(this.marker.item.position)
	}

	async getUserInfo() {
		this.userInfo = await this.aws.getUsers(this.marker.item.user)
	}

	async updateAlertComments() {
		this.state.globalAlerts = await this.aws.getAlerts(this.state.currentGroup)
		const alert = this.state.globalAlerts.find(alert => alert.id == this.marker.item.id)
		this.marker.item = alert
	}

	async showCommentCreate() {
		const modal = await this.modalCtrl.create({
			component: CommentCreateComponent,
			componentProps: {
				alert: this.marker.item
			},
			swipeToClose: true,
			cssClass: 'my-custom-modal-css',
			backdropDismiss: true
		})

		modal.onDidDismiss().then(() => this.updateAlertComments())
		return await modal.present()
	}

	closeModal() {
		this.modalCtrl.dismiss()
	}
}
