/** @format */

import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core'
// import videojs from 'video.js';
// import "@videojs/http-streaming";
import { AwsService } from '../../../service/aws/aws.service'
import * as moment from 'moment'
import { BehaviorSubject, Observable } from 'rxjs'
moment.locale('pt-br')

declare let shaka: any
@Component({
	selector: 'video-hist-stream',
	templateUrl: './video-hist.component.html',
	styleUrls: ['./video-hist.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VideoHistComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
	// see options: https://github.com/videojs/video.js/blob/maintutorial-options.html

	//Player renderization related variables
	@ViewChild('videoPlayer') videoElementRef: ElementRef | undefined
	@ViewChild('videoContainer') videoContainerRef: ElementRef | undefined
	private videoElement: HTMLVideoElement | undefined
	private videoContainerElement: HTMLDivElement | undefined
	private player: any = null
	private overlayUI: any = null

	public time = 'Em Construção'
	public duration = 0

	@Input() hls: string
	private myHLS: string = null

	@Input() endDate: string = null
	private myEndDate: string = null

	@Input() range: number = null
	private myRange: number = 6

	@Input() playbackSpeed: number = 1
	private myPlaybackSpeed: number = 1

	@Input() knobListener: Observable<any> = null

	@Input() playerStatusListener: Observable<any> = null

	private trackerSubject: BehaviorSubject<any> = new BehaviorSubject(null)
	@Output() trackerPosition: Observable<any> = this.trackerSubject.asObservable()
	private intervalFn: any = null

	@Output() videoStartDate: EventEmitter<any> = new EventEmitter()
	@Output() videoRange: EventEmitter<any> = new EventEmitter()

	private myStreamLink: string = null

	constructor(private readonly aws: AwsService) {}

	ngAfterViewInit(): void {
		this.installShakaPlayer()
	}

	private installShakaPlayer(): void {
		shaka.polyfill.installAll()
		if (shaka.Player.isBrowserSupported()) {
			this.videoElement = this.videoElementRef?.nativeElement
			this.videoContainerElement = this.videoContainerRef?.nativeElement
		} else {
			console.error('Browser not supported!')
		}
	}

	ngOnInit() {
		if (this.knobListener)
			this.knobListener.subscribe(timePosition => {
				if (this.player && this.videoElement) {
					const position = parseInt(timePosition)
					this.videoElement.currentTime = position
					this.playVideo()
				}
			})

		if (this.playerStatusListener)
			this.playerStatusListener.subscribe(status => {
				this.pauseVideo()
			})
	}

	private playVideo() {
		this.killInterval()
		this.intervalFn = setInterval(() => {
			const time = this.videoElement.currentTime
			this.trackerSubject.next(time)
		}, 300)
		this.videoElement.play()
	}

	private pauseVideo() {
		if (this.player && this.videoElement) {
			this.videoElement.pause()
			this.killInterval()
		}
	}

	private killInterval() {
		if (this.intervalFn) {
			clearInterval(this.intervalFn)
			this.intervalFn = null
		}
	}

	ngOnChanges() {
		if (this.myHLS !== this.hls || this.myEndDate !== this.endDate || this.myRange !== this.range) {
			this.myHLS = this.hls
			this.myEndDate = this.endDate
			this.myRange = this.range

			this.getHLS(this.range, this.endDate).then(streamLink => {
				this.myStreamLink = streamLink
				this.startPlayer(streamLink)
			})
		}

		if (this.myPlaybackSpeed !== this.playbackSpeed) {
			this.myPlaybackSpeed = this.playbackSpeed
			this.changePlaybackSpeed(this.myPlaybackSpeed)
		}
	}

	async getHLS(range: number, endTimestamp: string) {
		try {
			if (!this.myHLS) {
				return null
			}
			const response = await this.aws.getBackupHls({
				camera_id: this.myHLS.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
				range: range ? range : 6,
				endTime: endTimestamp
			})

			// let listKinesis = await this.aws.getMp4List({
			// 	camera_id: this.myHLS.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			// 	endTime: endTimestamp,
			// 	range: range ? range : 6
			// })

			// listKinesis = listKinesis.sort(function (a, b) {
			// 	return new Date(a.dthStart).getTime() - new Date(b.dthStart).getTime()
			// })


			// this.videoStartDate.emit(listKinesis[0].dthStart)

			this.videoStartDate.emit(moment(endTimestamp).add(-range, 'hours').toISOString())
			return response ? response.HLSStreamingSessionURL : null
		} catch (error) {
			console.error(error)
			return null
		}
	}

	private async startPlayer(streamLink: string) {
		if (this.player) await this.destroyPlayer()
		if (!streamLink) return
		this.player = new shaka.Player(this.videoElement)
		this.overlayUI = new shaka.ui.Overlay(this.player, this.videoContainerElement, this.videoElement)
		this.overlayUI.configure({
			addSeekBar: false,
			addBigPlayButton: true,
			controlPanelElements: []
		})
		this.player.configure('manifest.defaultPresentationDelay', 0)
		this.player
			.load(streamLink)
			.then(() => {
				this.playVideo()
				this.videoRange.emit(this.player.seekRange().end)
			})
			.catch((e: any) => {
				console.error(e)
			})
	}

	private async destroyPlayer() {
		await this.player.destroy()
		this.player = null
		await this.overlayUI.destroy()
		this.overlayUI = null
	}

	private changePlaybackSpeed(playbackSpeed: number): void {
		if (this.player) this.player.trickPlay(playbackSpeed)
	}

	ngOnDestroy() {
		if (this.player) this.player.destroy()
		if (this.intervalFn) {
			clearInterval(this.intervalFn)
			this.intervalFn = null
		}
	}
}
