/** @format */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { CamTimelineComponent } from 'src/app/modals/cam-timeline/cam-timeline.component'
import { ICam } from 'src/app/models/cam.interface'
import { AwsService } from 'src/service/aws/aws.service'

@Component({
	selector: 'rdsg-timeline-handler',
	templateUrl: './timeline-handler.component.html',
	styleUrls: ['./timeline-handler.component.scss']
})
export class TimelineHandlerComponent implements OnInit {
	public camID: string = 'guararapes11'
	public cam: ICam
	baseMarker = '../../../assets/marker/'
	public timelineData: any[] = null
	public videoLink: string = ''

	constructor(private readonly aws: AwsService, private cd: ChangeDetectorRef, public modalCtrl: ModalController) {}

	ngOnInit() {
		
		this.camID = this.cam.id
		
		this.getTimelineData(this.camID)
	}

	private async getTimelineData(camid): Promise<void> {
		try {
			const response = await this.aws.getBackup(camid)
			this.timelineData = response ? response : null
		} catch (error) {
			console.error(error)
		}
	}

	public async handleDisplayVideo(key: string): Promise<void> {
		try {
			const response = await this.aws.getBackupVideo(key)
			this.videoLink = response ? response : null
			this.cd.detectChanges()
		} catch (error) {
			console.error(error)
		}
	}

	public async onDismissModal(): Promise<boolean> {
		return await this.modalCtrl.dismiss()
	}

}
