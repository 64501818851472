/** @format */

import { Injectable, OnInit } from '@angular/core'
import { AwsService } from '../aws/aws.service'
// import { SmartService } from '../aws/smart.service';

import { LoadingController, ModalController } from '@ionic/angular'
import { Router } from '@angular/router'
import Amplify, { Auth } from 'aws-amplify'
import { ToastController } from '@ionic/angular'
import { AlertController } from '@ionic/angular'
// import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
// import * as d3 from 'd3';
import * as Cookies from 'js-cookie'
import { AppStateService } from 'src/app/state/app-state.service'

declare const aws_cognito_region
declare const aws_cognito_identity_pool_id
declare const aws_user_pools_id
@Injectable({
	providedIn: 'root'
})
export class UserService implements OnInit {
	// public userData: any;
	public ui = 'man'

	public pushToken: string
	public loggedIn = false
	private awsCognitoIdentityPoolId = 'us-east-1:fa662248-c628-4b94-a7d6-3b08e665ca75'
	private awsCognitoRegion = 'us-east-1'
	public loading: any
	public userData = {
		userName: '',
		password: ''
	}

	public filter = ''

	public userInfo = {
		area: '',
		email: '',
		jobCenter: '',
		jobCenterList: [],
		jobType: '',
		name: 'Carregando Nome',
		plannigGroup: '6',
		pn: '6',
		sector: 'null',
		site: 'VSB',
		search: []
	}

	public dark: boolean

	constructor(
		private state: AppStateService,
		private router: Router,
		private aws: AwsService,
		// private smart: SmartService,
		public loadingController: LoadingController,
		public toastController: ToastController,
		public alertController: AlertController,
		public modalController: ModalController
	) {
		this.userData = this.getUser()

		if (this.state.token) this.setTokenRefresher()
	}

	ngOnInit(): void {
		this.state.setUser(this.loadUserData(this.state.globalUserName))
		this.userInfo = JSON.parse(Cookies.get('userInfo'))
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
	}

	public saveToken(token) {
		this.pushToken = token
		Cookies.set('token', token)
	}

	// async showWelcome() {
	//   const modal = await this.modalController.create({
	//     component: WelcomeComponent,
	//     cssClass: 'fullScreenModal',
	//     componentProps: {
	//       'firstName': this.userData.userName.split('.')[0].toString(),
	//       'lastName': this.userData.userName.split('.')[1].toString(),
	//     }
	//     });

	//   modal.onDidDismiss().then((result) => {
	//     if (result.data) {

	//       this.userInfo.name = result.data.name;
	//       this.userInfo.pn = result.data.pn;
	//       this.userInfo.sector = result.data.sector;
	//       this.userInfo.area = result.data.area;
	//       this.userInfo.email = this.userData.userName + this.userData.emailType

	//       this.aws.putUser(this.userInfo).then(res=> {
	//         this.getUser();
	//       });

	//       // console.log(result)
	//       // this.doService.actionPlan.push(result.data.item);
	//       // this.up.toDo = result.data.item;
	//       // this.up.toDo.local = this.doService.done.location
	//       // this.up.toDo['mother'] = this.doService.done.id

	//       // this.up.updateToDo();
	//     }
	//   });
	//   return await modal.present();
	// };

	configure() {
		Amplify.configure({
			Auth: {
				// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
				// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

				// REQUIRED - Amazon Cognito Region
				region: 'us-east-1',

				// OPTIONAL - Amazon Cognito Federated Identity Pool Region
				// Required only if it's different from Amazon Cognito Region
				// identityPoolRegion: 'XX-XXXX-X',

				// OPTIONAL - Amazon Cognito User Pool ID
				userPoolId: 'us-east-1_v1uAaLV2F',

				// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
				userPoolWebClientId: '61m6rdbp6kcpcko04fh48eia6e',

				// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
				mandatorySignIn: true,

				// OPTIONAL - Configuration for cookie storage
				// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
				// cookieStorage: {
				// // REQUIRED - Cookie domain (only required if cookieStorage is provided)
				//     domain: '.yourdomain.com',
				// // OPTIONAL - Cookie path
				//     path: '/',
				// // OPTIONAL - Cookie expiration in days
				//     expires: 365,
				// // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
				//     sameSite: "strict" | "lax",
				// // OPTIONAL - Cookie secure flag
				// // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
				//     secure: true
				// },

				// OPTIONAL - customized storage object
				// storage: MyStorage,

				// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
				authenticationFlowType: 'USER_PASSWORD_AUTH'

				// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
				// clientMetadata: { myCustomKey: 'myCustomValue' },

				// OPTIONAL - Hosted UI configuration
				// oauth: {
				//     domain: 'your_cognito_domain',
				//     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
				//     redirectSignIn: 'http://localhost:3000/',
				//     redirectSignOut: 'http://localhost:3000/',
				//     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
				// }
			}
		})
	}

	async login(userData: any): Promise<any> {
		try {
			this.presentLoading()
			this.configure()

			const username = userData.userName
			const password = userData.password

			const response = await Auth.signIn({
				username, // Required, the username
				password
			})

			if (response && response.signInUserSession) {
				Cookies.set('token', response.signInUserSession.idToken.jwtToken)
				this.aws.setToken(response.signInUserSession.idToken.jwtToken)
				this.state.setToken(response.signInUserSession.idToken.jwtToken)
			}
			this.loading.dismiss()
		} catch (err) {
			if (err.code === 'UserNotFoundException') {
				this.presentToast('Usuário não encontrado')
			} else if (err.code === 'NotAuthorizedException') {
				this.presentToast('Usuário ou senha incorretos')
			} else {
				this.presentToast(err.code)
			}
			this.loading.dismiss()
		}
	}

	async loadUserData(userName): Promise<any> {
		try {
			const response = await this.aws.getUsers(userName)
			return response ? response : null
		} catch (err) {}
	}

	logout() {
		Cookies.remove('userData')
		Cookies.remove('token')
		this.state.unsetData()
		this.router.navigate(['/login'])
	}

	createUser(userInfo: any, token: any) {
		console.log('Aqui: ' + token)
	}

	singUp(userData: any) {
		this.configure()

		this.userData = userData

		const username = userData.userName
		const password = userData.password
		const email = userData.userName

		if (userData.token) {
			Amplify.configure({
				Auth: {
					// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
					// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

					// REQUIRED - Amazon Cognito Region
					region: 'us-east-1',

					// OPTIONAL - Amazon Cognito Federated Identity Pool Region
					// Required only if it's different from Amazon Cognito Region
					// identityPoolRegion: 'XX-XXXX-X',

					// OPTIONAL - Amazon Cognito User Pool ID
					userPoolId: 'us-east-1_v1uAaLV2F',

					// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
					userPoolWebClientId: '61m6rdbp6kcpcko04fh48eia6e',

					// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
					mandatorySignIn: false,

					// OPTIONAL - Configuration for cookie storage
					// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
					// cookieStorage: {
					// // REQUIRED - Cookie domain (only required if cookieStorage is provided)
					//     domain: '.yourdomain.com',
					// // OPTIONAL - Cookie path
					//     path: '/',
					// // OPTIONAL - Cookie expiration in days
					//     expires: 365,
					// // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
					//     sameSite: "strict" | "lax",
					// // OPTIONAL - Cookie secure flag
					// // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
					//     secure: true
					// },

					// OPTIONAL - customized storage object
					// storage: MyStorage,

					// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
					authenticationFlowType: 'USER_PASSWORD_AUTH'

					// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
					// clientMetadata: { myCustomKey: 'myCustomValue' },

					// OPTIONAL - Hosted UI configuration
					// oauth: {
					//     domain: 'your_cognito_domain',
					//     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
					//     redirectSignIn: 'http://localhost:3000/',
					//     redirectSignOut: 'http://localhost:3000/',
					//     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
					// }
				}
			})
		}

		Auth.signUp({
			username,
			password,
			attributes: {
				email // optional

				// phone_number,   // optional - E.164 number convention
				// other custom attributes
			}
		})
			.then(data => {
				this.presentAlert('Confirmação', 'Verifique seu email e confirme a inscrição')
				this.router.navigate(['/login'])
			})
			.catch(err => this.presentToast(err.message))
	}

	async forgotPassword(userEmail: string): Promise<any> {
		try {
			this.presentLoading()
			this.configure()
			const response = await Auth.forgotPassword(
				userEmail // Required, the username
			)
			this.loading.dismiss()
		} catch (err) {
			this.loading.dismiss()

			if (err.code === 'LimitExceededException')
				this.presentToast(`Limite de tentativas de redefinição de senha excedido. Tente novamente mais tarde.`)
			else this.presentToast(`Não foi possível enviar o código de confirmação. Erro código ${err.code}`)
			throw err
		}
	}

	async forgotPasswordSubmit(username, code, newPassword): Promise<any> {
		try {
			this.presentLoading()
			this.configure()
			const response = await Auth.forgotPasswordSubmit(username, code, newPassword)
			this.loading.dismiss()
		} catch (err) {
			this.loading.dismiss()
			if (err.code === 'CodeMismatchException')
				this.presentToast(
					`O código de segurança fornecido não confere. Verifique o código digitado e tente novamente.`
				)
			else this.presentToast(`Não foi possível redefinir sua senha. Erro código ${err.code}`)
			throw err
		}
	}

	// After retrieving the confirmation code from the user
	confirmCode(username, code) {
		Auth.confirmSignUp(username, code, {
			// Optional. Force user confirmation irrespective of existing alias. By default set to True.
			forceAliasCreation: true
		})
			.then()
			.catch()
	}

	public setTokenRefresher(): any {
		setInterval(this.refreshToken, 300000)
	}

	async refreshToken() {
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser()
			const currentSession = cognitoUser.signInUserSession
			cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
				// do something with the new session
				this.aws.setToken(session.idToken.jwtToken)
				this.state.setToken(session.idToken.jwtToken)
			})
		} catch (e) {
			console.error(e)
		}
	}

	resendCode(username) {
		Auth.resendSignUp(username)
			.then(() => {})
			.catch(e => {})
	}

	async presentLoading() {
		this.loading = await this.loadingController.create({
			spinner: 'bubbles',
			message: 'Bem Vindo',
			translucent: true,
			cssClass: 'customLoading',
			duration: 3000
		})
		await this.loading.present()

		// const { role, data } = await loading.onDidDismiss();

		//
	}

	async presentToast(text) {
		const toast = await this.toastController.create({
			color: 'danger',
			message: text,
			duration: 2000
		})
		toast.present()
	}

	async presentAlert(title, text) {
		const alert = await this.alertController.create({
			header: title,
			// subHeader: 'Subtitle',
			message: text,
			buttons: ['OK']
		})

		await alert.present()
	}

	public getUser() {
		const u = Cookies.get('userData')
		return u ? JSON.parse(u) : null
	}

	setSelectedGroup(group: string) {
		Cookies.set('group', group)
	}
	getSelectedGroup() {
		const g = Cookies.get('group')
		return g ? g : null
	}
}
