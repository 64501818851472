import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGroup } from 'src/app/models/group.interface'
import { AwsService } from 'src/service/aws/aws.service';

@Component({
  selector: 'rdsg-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  @Input() group: IGroup
  @Output() groupSelected = new EventEmitter<any>();

  public page = 1;
  public pages = [];
  public areas = [];
  public units = [];
  public unit = ""
  public cameras = [];
  public camerasTemp = [];
  public showMenu = false;
  public displayUnit = ""
  constructor(
    private aws: AwsService,
  ) {

  }

  ngOnInit() {
    this.aws.getAreas().then(res => {
      this.areas = res;
    })
    // this.aws.getHls(null).then(x=>{
    //   this.hls = x.HLSStreamingSessionURL;

    this.openNav();
    //   

    // })
  }

  showUnit(item) {
    this.displayUnit = item;
    this.getUnit(item);
  }

  getUnit(area) {
    this.aws.getUnit(area).then(res => {
      this.units = res
      
    })
  }

  getCameras(unit) {
    this.closeNav();
    this.unit = unit;
    this.groupSelected.emit({
      target: {
        value: this.unit
      }
    });

    // this.aws.getId(unit).then(res => {
    //   this.cameras = res

    //   // 
    //   let x = Math.ceil(this.cameras.length / 4);
    //   this.pages = [];
    //   for (var i = 0; i < x; i++) {
    //     this.pages.push(i + 1)
    //   }
    //   this.page = 0;
    //   this.pagination(this.page)
    // });
  }

  

  pagination(x) {
    this.page = x;
    this.camerasTemp = this.cameras.slice().splice(x * 4, 4);
  }

  /* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
  openNav() {
    document.getElementById("mySidenav").style.width = "300px";
    document.getElementById("main").style.marginLeft = "300px";
    this.showMenu = true;
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    this.showMenu = false;
  }


}