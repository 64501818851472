/** @format */

import { LoadingController, ToastController } from '@ionic/angular'

import { HttpClient } from '@angular/common/http'
import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as Cookies from 'js-cookie'
import { IUser } from 'src/app/models/user.interface'
import { IAlert } from 'src/app/models/alert.interface'
import { IGroup } from 'src/app/models/group.interface'
import { IGroupCamera } from 'src/app/models/group-camera.interface'

// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/toPromise';

@Injectable({
	providedIn: 'root'
})
export class AwsService {
	private headers
	public tokenShare

	// private apiGateway = "https://api-qas.vallsmart.com/cerebro-qas"; // teste
	// private apiSapInt = "/sap"

	private apiGateway = 'https://8i7xhzovhc.execute-api.us-east-1.amazonaws.com/nexus' // producao

	private loader: any
	alertController: any
	public progress = true

	constructor(
		private router: Router,
		private http: HttpClient,
		public toastController: ToastController,
		public loadingController: LoadingController
	) {
		this.setToken(null)
	}

	createLoader() {
		const online = window.navigator.onLine
		// alert(online)
		return this.loadingController.create({
			message: 'Loading'
		})
	}

	public setToken(token) {
		let tk = Cookies.get('token')
		console
		this.headers = new HttpHeaders({ Authorization: tk })
		this.headers.append('Content-Type', 'application/json')
		this.headers.append('Access-Control-Allow-Origin', '*')
		Cookies.set('header', this.headers)
	}

	ngOnInit(): void {
		this.headers = JSON.parse(Cookies.get('header'))

		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
	}

	async getHls(location: string) {
		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(
					this.apiGateway + '/jynx/hls',
					{
						camera_id: location
					},
					{ headers: this.headers }
				)
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getBackupHls(data: any) {
		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(
					this.apiGateway + '/jynx/hls/hist',
					data,
					{ headers: this.headers }
				)
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getAreas() {
		this.loader = this.createLoader()
		this.progress = true
		this.presentLoading()
		try {
			const res = await this.http.post(this.apiGateway + '/camera/area', null, { headers: this.headers }).toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getUnit(location: string) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(this.apiGateway + '/camera/unit', { area: location }, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getId(location: string) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(this.apiGateway + '/camera/id', { unit: location }, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getBackup(camid: string): Promise<any> {
		try {
			this.presentLoading()
			this.loader = this.createLoader()
			this.progress = true

			const res = await this.http
				.post(this.apiGateway + '/backup/list', { id: camid }, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getBackupVideo(videoKey: string): Promise<any> {
		try {
			this.presentLoading()
			this.loader = this.createLoader()
			this.progress = true

			const res = await this.http
				.post(this.apiGateway + '/backup/video', { key: videoKey }, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async createGroup(payload: IGroup): Promise<any> {
		try {
			this.presentLoading()
			this.loader = this.createLoader()
			this.progress = true

			const res = await this.http
				.put(this.apiGateway + '/group/user', payload, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async removeFromGroup(payload: IGroup): Promise<any> {
		try {
			this.presentLoading()
			this.loader = this.createLoader()
			this.progress = true

			const res = await this.http
				.put(this.apiGateway + '/group/user/delete', payload, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getGroupCameras(groupId: string): Promise<any> {
		try {
			this.presentLoading()
			this.loader = this.createLoader()
			this.progress = true

			const res = await this.http
				.post(this.apiGateway + '/group/camera', { group: groupId }, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async putGroupCameras(params: IGroupCamera): Promise<any> {
		try {
			this.presentLoading()
			this.loader = this.createLoader()
			this.progress = true

			const res = await this.http
				.put(this.apiGateway + '/group/camera', params, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getMP4(data: any) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http.post(this.apiGateway + '/jynx/mp4', data, { headers: this.headers }).toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getMp4List(data: any) {
		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(
					this.apiGateway + '/mp4/list',
					data,
					{ headers: this.headers }
				)
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getRecovery(data: any) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http.put(this.apiGateway + '/jynx/recovery', data, { headers: this.headers }).toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getRecoveryList(data: any) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http.post(this.apiGateway + '/jynx/recovery', data, { headers: this.headers }).toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}



	async presentLoading() {
		const loading = await this.loadingController.create({
			message: 'Carregando...',
			duration: 1000
		})
		await loading.present()

		const { role, data } = await loading.onDidDismiss()
	}

	private handleResponse(res, dismiss) {
		this.progress = false

		if (dismiss) {
			// this.loader.dismiss();
		}
		try {
			return res.json()
		} catch (e) {
			return res
		}
	}

	private async handleError(err) {
		this.progress = false

		// this.loader.dismiss();
		// alert(JSON.stringify(err));
		console.error(err)
		this.presentToastError(err.message)
		// this.router.navigate(["/login"]);
	}

	async presentToastError(message) {
		const toast = await this.toastController.create({
			header: 'Vish!',
			message: message,
			position: 'bottom',
			color: 'danger',
			buttons: [
				{
					text: 'fechar',
					role: 'cancel',
					handler: () => {
						
					}
				}
			]
		})
		toast.present()
	}

	async presentAlert(title, text) {
		const alert = await this.alertController.create({
			header: title,
			// subHeader: 'Subtitle',
			message: text,
			buttons: ['OK']
		})
		await alert.present()
	}

	async getAlerts(group: string) {
		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(
					this.apiGateway + '/group/alerts',
					{
						group: group
					},
					{ headers: this.headers }
				)
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async putAlert(params: IAlert) {
		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.put(this.apiGateway + '/group/alerts', params, { headers: this.headers })
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getGroup() {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http.post(this.apiGateway + '/groups', {}, { headers: this.headers }).toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async putGroup(params: IGroup) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http.put(this.apiGateway + '/groups', params, { headers: this.headers }).toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getUsers(user: string) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(
					this.apiGateway + '/users',
					{
						user: user
					},
					{ headers: this.headers }
				)
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async putUser(params: IUser) {
		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http.put(this.apiGateway + '/users', params, { headers: this.headers }).toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getGroupByUser(user: string) {
		this.presentLoading()

		this.loader = this.createLoader()
		this.progress = true
		try {
			const res = await this.http
				.post(
					this.apiGateway + '/group/user',
					{
						user: user
					},
					{ headers: this.headers }
				)
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}

	async getGroupData(group: string): Promise<any> {
		try {
			this.presentLoading()

			this.loader = this.createLoader()
			this.progress = true
			const res = await this.http
				.post(
					this.apiGateway + '/group',
					{
						group: group
					},
					{ headers: this.headers }
				)
				.toPromise()
			return this.handleResponse(res, true)
		} catch (err) {
			return await this.handleError(err)
		}
	}
}
