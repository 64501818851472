/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { input } from 'aws-amplify'
import { CamTimelineComponent } from 'src/app/modals/cam-timeline/cam-timeline.component'
import { ICam } from 'src/app/models/cam.interface'
import { DownloadHandlerComponent } from '../download-handler/download-handler.component'
import { ShareHandlerComponent } from '../share-handler/share-handler.component'
import { TimelineHandlerComponent } from '../timeline-handler/timeline-handler.component'

@Component({
	selector: 'rdsg-television',
	templateUrl: './television.component.html',
	styleUrls: ['./television.component.scss']
})
export class TelevisionComponent implements OnInit {
	@Input() cam: ICam
	hls: string
	@Input() share: boolean
	
	baseMarker = '../../../assets/marker/'
	constructor(private modalCtrl: ModalController) {}

	ngOnInit() {
		this.hls = this.cam.id
	}

	public async showModalDownload(hls) {
		const modal = await this.modalCtrl.create({
			component: DownloadHandlerComponent,
			showBackdrop: true,
			id: 'DOWNLOAD_HANDLER_MODAL',
			componentProps: {
				hls: hls
			}
		})
		return await modal.present()
	}

	public async handleShareVideo(): Promise<void> {
		console.log(this.cam)
		const modal = await this.modalCtrl.create({
			component: ShareHandlerComponent,
			componentProps: {
				cam: this.cam
			},
			showBackdrop: true,
			id: 'SHARE_HANDLER_MODAL'
		})
		return await modal.present()
	}

	public async handleCheckTimeline(): Promise<void> {
		const modal = await this.modalCtrl.create({
			component: TimelineHandlerComponent,
			showBackdrop: false,
			id: 'TIMELINE_HANDLER_MODAL'
		})
		return await modal.present()
	}

	async showModalCam() {
		const modal = await this.modalCtrl.create({
			component: CamTimelineComponent,
			swipeToClose: true,
			componentProps: {
				cams: [this.cam],
				icon: {
					url: 'camera.svg',
					scaledSize: new google.maps.Size(30, 30),
					label: 'Câmera'
				}
			},
			cssClass: 'rdsg-timeline-modal',
			backdropDismiss: true
		})
		return await modal.present()
	}
}
