/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { IAlert } from 'src/app/models/alert.interface'
import { AwsService } from 'src/service/aws/aws.service'
import { UserService } from 'src/service/user/user.service'
import { v4 as uuidv4 } from 'uuid'

@Component({
	selector: 'rdsg-alert-form',
	templateUrl: './alert-form.component.html',
	styleUrls: ['./alert-form.component.scss']
})
export class AlertFormComponent implements OnInit {
	alertForm = this.fb.group({
		category: ['', Validators.required],
		description: ['']
	})

	baseIcon = '../../../assets/marker/'
	categories = [
		{
			value: 'emergencia',
			icon: 'emergencia.svg',
			label: 'Emergência'
		},
		{
			value: 'roubo',
			icon: 'roubo.svg',
			label: 'Roubo'
		},
		{
			value: 'drogas',
			icon: 'drogas.svg',
			label: 'Drogas'
		},
		{
			value: 'vandalismo',
			icon: 'vandalismo.svg',
			label: 'Vandalismo'
		},
		{
			value: 'violencia',
			icon: 'violencia.svg',
			label: 'Violência'
		},
		{
			value: 'pessoaSuspeita',
			icon: 'pessoasuspeita.svg',
			label: 'Pessoa Suspeita'
		},
		{
			value: 'veiculoSuspeita',
			icon: 'carrosuspeito.svg',
			label: 'Veiculo Suspeito'
		},
		{
			value: 'informativo',
			icon: 'info.svg',
			label: 'Informativo'
		}
	]
	@Input() position: any
	geocoder
	constructor(
		public modalCtrl: ModalController,
		private fb: FormBuilder,
		public userAuth: UserService,
		private router: Router,
		public awsService: AwsService
	) {}

	ngOnInit() {
		this.geocoder = new google.maps.Geocoder()
	}

	async onSubmit() {
		const us = this.userAuth.getUser()
		const results = await this.geocoder.geocode({ location: this.position })
		const address = results.results[0].address_components
		const alert: IAlert = {
			...this.alertForm.value,
			createdAt: new Date(),
			group: this.userAuth.getSelectedGroup(),
			position: this.position,
			comments: [],
			address: `${address[1].short_name}, ${address[0].short_name} - ${address[2].short_name}`,
			user: us.user,
			id: uuidv4()
		}

		this.awsService.putAlert(alert).then(res => {
			this.modalCtrl.dismiss()
		})
	}

	closeModal() {
		this.modalCtrl.dismiss()
	}
	segmentChanged(ev: any) {
		
	}
}
