/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { AwsService } from '../../../service/aws/aws.service'

@Component({
	selector: 'app-download',
	templateUrl: './download.component.html',
	styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
	@Input() camera_id: string

	public dthStart
	public dthEnd
	public dthMax = new Date().toISOString()

	constructor(private aws: AwsService) {}

	ngOnInit() {}
	changeStart(event) {
		this.dthStart = event.detail.value

		
		let dt = new Date(this.dthStart)
		dt.setHours(dt.getHours() + 3)
		

		this.dthEnd = dt.toISOString()
	}

	maxDate() {}
	
	getMP4() {	
		const data  = {
			camera_id: this.camera_id,
			range: 3,
			endTime: this.dthEnd
		}
		console.warn(data)
		// const data = {
		// 	camera_id: this.camera_id,
		// 	time: new Date(new Date().toUTCString()).toISOString()
		// }

		this.aws.getMP4(data).then(res => {
			window.open(res, '_blank')
		})
	}
}
