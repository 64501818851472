/** @format */

import { HttpClientModule } from '@angular/common/http'
import { LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { TimelineModule } from 'primeng/timeline'
import { AwsService } from '../service/aws/aws.service'
import { UserService } from '../service/user/user.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ComponentsModule } from './components/components.module'
import { AlertDetailComponent } from './modals/alert-detail/alert-detail.component'
import { AlertFormComponent } from './modals/alert-form/alert-form.component'
import { CamDetailComponent } from './modals/cam-detail/cam-detail.component'
import { CamTimelineComponent } from './modals/cam-timeline/cam-timeline.component'
import { CommentCreateComponent } from './modals/comment-create/comment-create.component'
import { DownloadComponent } from './modals/download/download.component'
import { GroupCreateComponent } from './modals/group-create/group-create.component'
import { GroupMgmtComponent } from './modals/group-mgmt/group-mgmt.component'
import { AppStateService } from './state/app-state.service'
import { CalendarModule } from 'ion2-calendar'

import localePt from '@angular/common/locales/pt'
import { registerLocaleData } from '@angular/common'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { CamViewComponent } from './modals/cam-view/cam-view.component'

registerLocaleData(localePt)

@NgModule({
	declarations: [
		AppComponent,
		DownloadComponent,
		AlertDetailComponent,
		CamDetailComponent,
		CamTimelineComponent,
		CamViewComponent,
		AlertFormComponent,
		GroupCreateComponent,
		GroupMgmtComponent,
		CommentCreateComponent
	],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ComponentsModule,
		ReactiveFormsModule,
		FormsModule,
		TimelineModule,
		CalendarModule,
		NgxSliderModule
	],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		AwsService,
		UserService,
		AppStateService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
