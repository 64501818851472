/** @format */

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AwsService } from 'src/service/aws/aws.service'
import { AppStateService } from '../state/app-state.service'

@Injectable({
	providedIn: 'root'
})
export class FirstAccessGuard implements CanActivate {
	constructor(private readonly aws: AwsService, private router: Router, private readonly state: AppStateService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		try {
			console.log(this.state.globalUser)
			if (this.state.globalUser === null || !Object.keys(this.state.globalUser).length) {
				this.router.navigate(['/user/info-mgmt'])
				return false
			}

			return this.aws.getGroupByUser(this.state.globalUserName).then(response => {
				console.log(response)
				if (!response.length) {
					this.router.navigate(['/user/group-mgmt'], { queryParams: { new: true } })
					return false
				} else return true
			})
		} catch (error) {
			console.error(error)
			return false
		}
	}
}
