/** @format */

import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ICam } from 'src/app/models/cam.interface'
import { IGroupCamera } from 'src/app/models/group-camera.interface'
import { AppStateService } from 'src/app/state/app-state.service'
import { AwsService } from 'src/service/aws/aws.service'

@Component({
	selector: 'rdsg-share-handler',
	templateUrl: './share-handler.component.html',
	styleUrls: ['./share-handler.component.scss']
})
export class ShareHandlerComponent implements OnInit {
	@Input() cam: ICam
	user
	groups

	constructor(
		private readonly aws: AwsService,
		public modalCtrl: ModalController,
		private appState: AppStateService
	) {}

	ngOnInit() {
		this.getGroups()
		console.log(this.cam)
	}

	public async onDismissModal(): Promise<boolean> {
		return await this.modalCtrl.dismiss()
	}

	async getGroups() {
		this.groups = await this.aws.getGroupByUser(this.appState.globalUserName)
		this.groups.forEach(group => (group.selected = false))
	}

	async handleShareVideo() {
		const selecteds = this.groups.filter(group => group.selected)
		selecteds.forEach(async group => {
			const params: IGroupCamera = {
				group: group.group,
				id: this.cam.id,
				cam: this.cam,
				user: this.appState.globalUserName
			}
			const response = await this.aws.putGroupCameras(params)
		})

		this.modalCtrl.dismiss()
	}
}
