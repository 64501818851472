/** @format */

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'rdsg-comment',
	templateUrl: './comment.component.html',
	styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
	@Input() icon
	@Input() name
	@Input() date
	@Input() detail
	@Input() description
	constructor() {}

	ngOnInit() {}
}
