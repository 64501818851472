/** @format */

import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { IAlert } from 'src/app/models/alert.interface'
import { IComment } from 'src/app/models/comment.interface'
import { AppStateService } from 'src/app/state/app-state.service'
import { AwsService } from 'src/service/aws/aws.service'

@Component({
	selector: 'rdsg-comment-create',
	templateUrl: './comment-create.component.html',
	styleUrls: ['./comment-create.component.scss']
})
export class CommentCreateComponent implements OnInit {
	@Input() alert: IAlert

	commentForm = this.fb.group({
		comment: ['', Validators.required]
	})

	constructor(private readonly aws: AwsService, private fb: FormBuilder, private modalCtrl: ModalController, public state:AppStateService) {}

	ngOnInit() {}

	public async handleCreateComment() {
		try {
      const comment: IComment = {
        user: this.state.globalUser,
        createdAt: new Date(),
        description: this.commentForm.value.comment
      }
			this.alert.comments.push(comment)
			this.aws.putAlert(this.alert).then(res => {
				this.modalCtrl.dismiss()
			})
		} catch (error) {}
	}

	public async onDismissModal(): Promise<boolean> {
		return await this.modalCtrl.dismiss()
	}
}
