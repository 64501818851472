/** @format */

import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core'
// import videojs from 'video.js';
// import "@videojs/http-streaming";
import { AwsService } from '../../../service/aws/aws.service'

declare let shaka: any
@Component({
	selector: 'video-stream',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class VideoComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
	// see options: https://github.com/videojs/video.js/blob/maintutorial-options.html

	@ViewChild('videoPlayer') videoElementRef: ElementRef | undefined
	@ViewChild('videoContainer') videoContainerRef: ElementRef | undefined
	private videoElement: HTMLVideoElement | undefined
	private videoContainerElement: HTMLDivElement | undefined

	private player: any = null
	private overlayUI: any = null

	@Input() hls: string

	private hlsLink: string = ''
	constructor(private readonly aws: AwsService) {}

	ngAfterViewInit(): void {
		this.installShakaPlayer()
	}

	private installShakaPlayer(): void {
		shaka.polyfill.installAll()
		if (shaka.Player.isBrowserSupported()) {
			this.videoElement = this.videoElementRef?.nativeElement
			this.videoContainerElement = this.videoContainerRef?.nativeElement
		} else {
			console.error('Browser not supported!')
		}
	}

	ngOnInit() {}

	async ngOnChanges() {
		console.log(this.hls)
		try {
			if ((this.hlsLink === '' || this.player === null) && this.hls) {
				const response = await this.aws.getHls(this.hls.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
				this.hlsLink = response.HLSStreamingSessionURL
				this.initPlayer()
			}
		} catch (error) {
			console.error(error)
		}
	}

	private initPlayer() {
		this.player = new shaka.Player(this.videoElement)
		this.overlayUI = new shaka.ui.Overlay(this.player, this.videoContainerElement, this.videoElement)

		this.player
			.load(this.hlsLink)
			.then(() => this.videoElement?.play())
			.catch((e: any) => {
				console.error(e)
			})
	}

	ngOnDestroy() {
		if (this.player) this.player.destroy()
	}
}
