/** @format */

import { Component, Input, OnInit, OnChanges } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { initialize } from '@ionic/core'
import moment from 'moment'
import { AwsService } from 'src/service/aws/aws.service'

@Component({
	selector: 'rdsg-download-handler',
	templateUrl: './download-handler.component.html',
	styleUrls: ['./download-handler.component.scss']
})
export class DownloadHandlerComponent implements OnInit, OnChanges {
	@Input() camera_id: string
	@Input() endTime: string
	@Input() range: string

	public listFiles = []

	public initialDate = new Date().toISOString();
	public endDate: string;
	constructor(private readonly aws: AwsService, public modalCtrl: ModalController) {}

	ngOnInit() {}

	async ngOnChanges(changes: any): Promise<void> {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

		const listKinesis = await this.aws.getRecoveryList({
			camera_id: this.camera_id.toLowerCase(),
			// endTime: this.endTime,
			// range: this.range
		})

		const listS3 = await this.aws.getBackup(this.camera_id)
		this.listFiles = []

		this.listFiles = this.listFiles.concat(listKinesis.slice(0,50), listS3).sort(function (a, b) {
			return new Date(a.dthStart).getTime() - new Date(b.dthStart).getTime()
		})
	}

	public async onDismissModal(): Promise<boolean> {
		return await this.modalCtrl.dismiss()
	}

	public get maxDate() {
		const today = new Date()
		return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
	}

	public async download(item: any) {
		if (item.source === 'kinesis') {
			try {
				const payload = {
					camera_id: this.camera_id,
					endTime: item.dthEnd,
					range: item.range
				}

				console.log(payload)

				const response = await this.aws.getMP4(payload)
				window.open(response, '_blank')
			} catch (error) {
				console.error(error)
			}
		} else {
			const payload = {
				camera_id: this.camera_id.toLowerCase(),
				file: item
			}

			console.log(payload)

			const response = await this.aws.getRecovery(payload)
			window.open(response, '_blank')
		}
	}

	public async handleRecordDownload(): Promise<void> {
		try {
			var duration = moment(this.endDate).diff(moment(this.initialDate), 'hours', true);

			const payload = {
				camera_id: this.camera_id,
				endTime: moment(this.endDate).toISOString(),
				range: duration
			}

			const response = await this.aws.getMP4(payload)
			window.open(response, '_blank')
		} catch (error) {
			console.error(error)
		}
	}
}
