/** @format */

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { ICam } from 'src/app/models/cam.interface'

export const SINGLE_COLUMN = { columns: 1, length: 1 }
export const TWO_SQUARE = { columns: 2, length: 4 }
export const THREE_BY_TWO = { columns: 3, length: 6 }
export const THREE_SQUARE = { columns: 3, length: 9 }
export const FOUR_SQUARE = { columns: 4, length: 16 }

@Component({
	selector: 'rdsg-paginated-video-grid',
	templateUrl: './paginated-video-grid.component.html',
	styleUrls: ['./paginated-video-grid.component.scss']
})
export class PaginatedVideoGridComponent implements OnInit {
	@Input() videoIds: Observable<ICam[]>
	@Input() share: boolean

	private idsSubscription: Subscription

	public pages: number[] = []
	public currentPage: number = 1
	public currentGrid = SINGLE_COLUMN
	public full = false
	private _videoIds: ICam[] = []

	constructor(private readonly cd: ChangeDetectorRef) {}

	ngOnInit() {}

	ngOnChanges() {
		if (this.videoIds !== null && this.idsSubscription !== null)
			this.idsSubscription = this.videoIds.subscribe(videoIds => {
				if (!this.videoIds) return
				this._videoIds = videoIds

				this.updateBookSize()
			})
	}

	public setSingleColumnGrid(): void {
		this.currentGrid = SINGLE_COLUMN
		this.updateBookSize()
	}

	public setTwoSquareGrid(): void {
		this.currentGrid = TWO_SQUARE
		this.updateBookSize()
	}

	public setThreeByTwoGrid(): void {
		this.currentGrid = THREE_BY_TWO
		this.updateBookSize()
	}

	public setThreeSquareGrid(): void {
		this.currentGrid = THREE_SQUARE
		this.updateBookSize()
	}

	public setFourSquareGrid(): void {
		this.currentGrid = FOUR_SQUARE
		this.updateBookSize()
	}

	private updateBookSize(): void {
		const bookSize = Math.ceil(this._videoIds.length / this.currentGrid.length)
		this.pages = Array.from({ length: bookSize }, (_, i) => i + 1)
		if (this.currentPage > this.pages.length) this.currentPage = this.pages.length
		else this.currentPage = 1
	}

	public get slicedIds(): ICam[] {
		const start = (this.currentPage - 1) * this.currentGrid.length
		const end = start + this.currentGrid.length
		return this._videoIds.slice(start, end)
	}
}
