/** @format */

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { VideoComponent } from './video/video.component'
import { VideoHistComponent } from './video-hist/video-hist.component'
import { DefaultHeaderComponent } from './default-header/default-header.component'
import { PaginatedVideoGridComponent } from './paginated-video-grid/paginated-video-grid.component'
import { TelevisionComponent } from './television/television.component'
import { DownloadHandlerComponent } from './download-handler/download-handler.component'
import { CommentComponent } from './comment/comment.component'
import { TimelineHandlerComponent } from './timeline-handler/timeline-handler.component'
import { ShareHandlerComponent } from './share-handler/share-handler.component'
import { SideMenuComponent } from './side-menu/side-menu.component'

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule],
	declarations: [
		VideoComponent,
		VideoHistComponent,
		DefaultHeaderComponent,
		PaginatedVideoGridComponent,
		TelevisionComponent,
		DownloadHandlerComponent,
		CommentComponent,
		ShareHandlerComponent,
		TimelineHandlerComponent,
		SideMenuComponent
	],
	entryComponents: [VideoComponent,VideoHistComponent],
	exports: [
		VideoComponent,
		VideoHistComponent,
		DefaultHeaderComponent,
		PaginatedVideoGridComponent,
		DownloadHandlerComponent,
		TelevisionComponent,
		ShareHandlerComponent,
		CommentComponent,
		SideMenuComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
