/** @format */

import { Injectable } from '@angular/core'
import { IGroup } from '../models/group.interface'
import { IUser } from '../models/user.interface'
import { IAlert } from '../models/alert.interface'
import * as Cookies from 'js-cookie'

@Injectable({
	providedIn: 'root'
})
export class AppStateService {
	public isPassResetRequested: boolean = false
	public passResetEmail: string = null
	public globalUserName: string = null
	public globalUser: IUser = null
	public globalAlerts: IAlert[] = null
	public currentGroup: string = null
	public globalGroups: IGroup[] = []
	public token: any = null

	geocoder

	constructor() {
		this.initToken()
		this.initUserName()
		this.initUser()
		this.initGroup()
		this.initPassReset()
	}

	private initToken(): void {
		const token = Cookies.get('token')
		if (this.checkIntegrityOf(token)) {
			this.token = token ? token : null
		} else {
			Cookies.remove('token')
			this.token = null
		}
	}

	private initUserName(): void {
		const userName = Cookies.get('userName')
		this.globalUserName = userName ? userName : null
	}

	private initUser(): void {
		const user = Cookies.get('userData')
		this.globalUser = user ? JSON.parse(user) : null
	}

	private initGroup(): void {
		const group = Cookies.get('group')
		this.currentGroup = group ? group : null
	}

	private initPassReset(): void{
		const resetEmail = Cookies.get('passResetEmail')
		const isPassReset = Cookies.get('isPassResetRequested')
		this.passResetEmail = resetEmail ? resetEmail : null
		this.isPassResetRequested = isPassReset ? isPassReset : null
	}

	public setToken(token: any): void {
		Cookies.set('token', token)
		this.token = token
	}

	public isTokenValid(): boolean {
		return this.checkIntegrityOf(this.token)
	}

	private checkIntegrityOf(token: any): boolean {
		return this.nullCheck(token) && this.jwtCheck(token)
	}

	private nullCheck(objct: any): boolean {
		return objct !== null && objct !== undefined && objct !== ''
	}

	private jwtCheck(jwtToken: any): boolean {
		let tokenParts = jwtToken.split('.')
		if (tokenParts.length != 3) {
			return false
		}
		let parsedToken = JSON.parse(decodeURIComponent(escape(window.atob(tokenParts[1]))))
		if (parsedToken.exp < Date.now() / 1000) return false
		else return true
	}

	public setUserName(userName: string): void {
		Cookies.set('userName', userName)
		this.globalUserName = userName
	}

	public setUser(userData: any): void {
		Cookies.set('userData', JSON.stringify(userData))
		this.globalUser = userData
	}

	async getAddressByLatLng(position) {
		try {
			const results = await this.geocoder.geocode({ location: position })

			const address = results.results[0].formatted_address
			return address
		} catch (error) {
			console.error('Erro ao buscar localização')
		}
	}

	public setPassReset(): void{
		Cookies.set('isPassResetRequested', true)
		this.isPassResetRequested = true
	}

	public setPassResetEmail(email: string): void{
		Cookies.set('passResetEmail', email)
		this.passResetEmail = email
	}

	public clearPassResetData(): void {
		this.isPassResetRequested = false
		this.passResetEmail = null
		Cookies.remove('isPassResetRequested')
		Cookies.remove('passResetEmail')
	}

	public unsetData(): void {
		Cookies.remove('userName')
		Cookies.remove('userData')
		Cookies.remove('token')
		this.globalUser = null
		this.globalUserName = null
		this.token = null
	}
}
